import React from "react";

import { AppBar, Box, Toolbar, Typography, Button, Link } from "@mui/material/";

import Logo from "../images/CYBERTEC-LOGO.svg";
import LogoMobile from "../images/ICON_RGB-01.png";

export default function MenuBar({ config }) {
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([config], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "postgresql.conf";
    document.body.appendChild(element);
    element.click();
  };
  return (
    <AppBar color={"white"} position="static">
      <Toolbar>
        <Box display={"flex"} width="100%" justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Link href="https://www.cybertec-postgresql.com/en/">
              <Box
                component={"img"}
                alt={"logo"}
                src={Logo}
                sx={{
                  width: { md: "12.5rem" },
                  display: { xs: "none", md: "block" },
                }}
              ></Box>
            </Link>
            <Link href="https://www.cybertec-postgresql.com/en/">
              <Box
                href="https://www.cybertec-postgresql.com/en/"
                component={"img"}
                alt={"logo"}
                src={LogoMobile}
                sx={{
                  width: { xs: "2.5rem" },
                  display: { xs: "block", md: "none" },
                }}
              />
            </Link>

            <Box ml={2} mb={"0.8rem"}>
              <Typography
                variant="cybertecheadding"
                component="div"
                sx={{ flexGrow: 1 }}
                color={"primary.dark"}
              >
                PostgreSQL Configurator
              </Typography>
            </Box>
          </Box>
          <Button variant="outlined" onClick={downloadTxtFile}>
            Download Config
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

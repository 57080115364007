import React,{useEffect} from "react";

import { Typography } from "@mui/material";


export default function ConfigGenerator({setConfig, ram, databaseSize, diskType, diskSize, connection, workload, backup, replica, transaction, version, cpu, experimental, recycle}) {
    //mathing the ram and the databasesize to the correct value
    var tmp = databaseSize / 25;
    databaseSize = 10 ** tmp;
    tmp = ram / 25;
    ram = 2 ** tmp;

    const nearestPowerOfTwo = num => {
        // dealing only with non-negative numbers
        if(num < 0){
            num *= -1;
        }
        let base = 1;
        while(base < num){
            if(num - base < Math.floor(base / 2)){
              return base;
            };
            base *= 2;
        };
        return base;
      };

    //calculating shared_buffers
    var shared_buffers = Math.round((1024 * ram) / 4)
    if 	(shared_buffers > databaseSize*1024)
    {
        shared_buffers = databaseSize*1024
    }
    

    //calculating work_mem and maintenance_work_mem
    var work_mem = Math.floor((ram * 0.25/Math.log(connection) * 10) / 10) * 10 + 32
    var maintenance_work_mem = work_mem * 10
    var huge_pages = "off"

    if (ram >= 32)
       huge_pages = 'try   # NB! requires also activation of huge pages via kernel params, see here for more: https://www.postgresql.org/docs/current/static/kernel-resources.html#LINUX-HUGE-PAGES'
    
    if	(workload === 1)
    {
	    work_mem = work_mem * 0.8
    }
    else if (workload === 2)
    {
	    work_mem = work_mem * 1
    }
    else if (workload === 3)
    {
	    work_mem = work_mem * 2
    }
    else
    {
  	  work_mem = work_mem * 4
    }

    work_mem = nearestPowerOfTwo(work_mem)

    //calculating effective_cache_size, effective_io_concurrency and random_page_cost
    var effective_cache_size = Math.round(ram * 0.7)
    var effective_io_concurrency = Math.round(diskSize / 2)

    if(diskType === 1){
        effective_io_concurrency = Math.min(Math.max(100,effective_io_concurrency * 100), 1000)
    } 
    if(diskType === 3){ 
        effective_io_concurrency = Math.min(effective_io_concurrency * 150, 1000)
    }

    var random_page_cost = 4
    if(diskType === 1) {
        random_page_cost = 1.25
    }
        
    if(diskType === 3){
        random_page_cost = 1.5
    }

    //building replication categorie
    var wal_level = "replica 		# consider using at least 'replica'"
    var max_wal_senders = Math.max(10,replica + 3)
    if	(backup === 3)
    {
	wal_level = "logical"
    }
    else if (backup === 1 && replica === 0)
    {
	wal_level = "replica		# consider using at least 'replica'"
	max_wal_senders = 0
    }

    var synchronous_commit = "on"
    if (parseInt(transaction) === 1) {
        synchronous_commit = "off"
    }

    //building checkpointing categorie
    var min_wal_size = Math.round(databaseSize * 1024 * 0.005)
    var max_wal_size = Math.round(databaseSize * 1024 * 0.01)
    if	(min_wal_size < 512)
    {
	min_wal_size = 512
	max_wal_size = 1024
    }
    else if (min_wal_size > 16384)
    {
	min_wal_size = 16384
	max_wal_size = 32768
    }

    var wal_keep_segments = Math.ceil((100 + max_wal_size * 2) / 16 / 10) * 10
    var wal_keep_size = Math.ceil((100 * 16 + max_wal_size * 2) / 10) * 10

    var wal_archiving = ""
    if (replica > 0 || backup !== 1){
      wal_archiving = `
# WAL archiving
archive_mode = on # having it on enables activating P.I.T.R. at a later time without restart›
archive_command = '/bin/true'  # not doing anything yet with WAL-s
      `
    }

    var replication = ""

    if (replica > 0) {
        if(version >= 13){
            replication = "wal_keep_size = '" + wal_keep_size +" MB'\n"
        } else {
            replication = "wal_keep_segments = " +  wal_keep_segments + "\n"
        }

       
        
    } else {
          replication = ""
      }
  
    if(parseInt(transaction) === 0){
        replication = `wal_writer_delay = 200ms
wal_writer_flush_after = 1MB\n` +
            replication
    }


    var advanced = ""

    if(version > 10){
      	var parallel = "max_worker_processes = " + cpu + "\n"
        parallel += "max_parallel_workers_per_gather = " + Math.round(cpu / 2) + "\n"
        parallel += "max_parallel_maintenance_workers = " + Math.round(cpu / 2) + "\n"
  		parallel += "max_parallel_workers = " + cpu + "\n"
  		parallel += "parallel_leader_participation = on\n"

    	advanced = "enable_partitionwise_join = on \n"
    	advanced += "enable_partitionwise_aggregate = on\n"

      if (version === 11 && parseInt(experimental) === 1){
        advanced += "jit = on\n"
        advanced += "jit_above_cost = 100000\n"
        advanced += "jit_optimize_above_cost = 500000\n"
        advanced += "jit_inline_above_cost = 500000\n"

      } else if (version > 11) {
        advanced += "jit = on\n"

      }
      if (version >= 14) {
        advanced += "max_slot_wal_keep_size = '" + (databaseSize * 0.1 >= 1000 ? databaseSize * 0.1 : 1000) + " MB'\n"
        advanced += "track_wal_io_timing = on\n"
      }
      if (version >= 15){
        advanced += "maintenance_io_concurrency = " + effective_io_concurrency + "\n"
        advanced +="wal_recycle = " + (parseInt(recycle) === 1 ? "on\n" : "off\n")
      }
    }
    else {
    	parallel += "max_worker_processes = " + cpu + "\n"
      	parallel += "max_parallel_workers_per_gather = " + Math.round(cpu / 2)
		parallel += "max_parallel_workers = " + cpu
    }

    var output = `# DISCLAIMER - Software and the resulting config files are provided AS IS - IN NO EVENT SHALL
# BE THE CREATOR LIABLE TO ANY PARTY FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
# DAMAGES, INCLUDING LOST PROFITS, ARISING OUT OF THE USE OF THIS SOFTWARE AND ITS DOCUMENTATION.

# Connectivity
max_connections = `+ connection+`
superuser_reserved_connections = 3

# Memory Settings
shared_buffers = '`+shared_buffers+` MB'
work_mem = '`+work_mem+` MB'
maintenance_work_mem = '`+maintenance_work_mem+` MB'
huge_pages = `+huge_pages+`
effective_cache_size = '`+effective_cache_size+` GB'
effective_io_concurrency = `+effective_io_concurrency+` # concurrent IO only really activated if OS supports posix_fadvise function
random_page_cost = `+random_page_cost+` # speed of random disk access relative to sequential access (1.0)

# Monitoring
shared_preload_libraries = 'pg_stat_statements'    # per statement resource usage stats
track_io_timing=on        # measure exact block IO times
track_functions=pl        # track execution times of pl-language procedures if any

# Replication
wal_level = `+wal_level+`
max_wal_senders = `+max_wal_senders+`
synchronous_commit = `+synchronous_commit+`

# Checkpointing: 
checkpoint_timeout  = '15 min' 
checkpoint_completion_target = 0.9
max_wal_size = '`+max_wal_size+` MB'
min_wal_size = '`+ min_wal_size+` MB'
`+wal_archiving+`

# WAL writing
wal_compression = on
wal_buffers = -1    # auto-tuned by Postgres till maximum of segment size (16MB by default)
`+replication+`

# Background writer
bgwriter_delay = 200ms
bgwriter_lru_maxpages = 100
bgwriter_lru_multiplier = 2.0
bgwriter_flush_after = 0

# Parallel queries: 
`+parallel+`
# Advanced features 
`+advanced+`

# General notes:
# Note that not all settings are automatically tuned.
#   Consider contacting experts at 
#   https://www.cybertec-postgresql.com 
#   for more professional expertise.
`

    useEffect(() => {
      setConfig(output)
    }, [output,setConfig])
    
  return (
    <Typography variant={"code"} style={{whiteSpace: 'pre-line'}}>
        {output}
    </Typography>
  );
}

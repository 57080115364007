import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Slider,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import NonLinearSlider from "../components/NonLinearSlider";
import ConfigGenerator from "../components/ConfigGenerator";

const ram_marks = [
  {
    value: 0,
    scaledValue: 1,
    label: "1",
  },
  {
    value: 25,
    scaledValue: 2,
    label: "2",
  },
  {
    value: 50,
    scaledValue: 4,
    label: "4",
  },
  {
    value: 75,
    scaledValue: 8,
    label: "8",
  },
  {
    value: 100,
    scaledValue: 16,
    label: "16",
  },
  {
    value: 125,
    scaledValue: 32,
    label: "32",
  },
  {
    value: 150,
    scaledValue: 64,
    label: "64",
  },
  {
    value: 175,
    scaledValue: 128,
    label: "128",
  },
  {
    value: 200,
    scaledValue: 254,
    label: "254",
  },
  {
    value: 225,
    scaledValue: 512,
    label: "512",
  },
  {
    value: 250,
    scaledValue: 1024,
    label: "1024",
  },
  {
    value: 275,
    scaledValue: 2048,
    label: "2048",
  },
];

const cpu_marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 33,
    label: "33",
  },
  {
    value: 41,
    label: "41",
  },
  {
    value: 49,
    label: "49",
  },
  {
    value: 57,
    label: "57",
  },
  {
    value: 65,
    label: "65",
  },
  {
    value: 72,
    label: "72",
  },
];

const disk_marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 13,
    label: "25",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 21,
    label: "21",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 29,
    label: "29",
  },
  {
    value: 32,
    label: "32",
  },
];

const database_size_marks = [
  {
    value: 0,
    scaledValue: 1,
    label: "1GB",
  },
  {
    value: 25,
    scaledValue: 10,
    label: "10GB",
  },
  {
    value: 50,
    scaledValue: 100,
    label: "100GB",
  },
  {
    value: 75,
    scaledValue: 1000,
    label: "1TB",
  },
  {
    value: 100,
    scaledValue: 10000,
    label: "10TB",
  },
  {
    value: 125,
    scaledValue: 100000,
    label: "100TB",
  },
];

const connection_marks = [
  {
    value: 20,
    label: "20",
  },
  {
    value: 520,
    label: "520",
  },
  {
    value: 1020,
    label: "1020",
  },
  {
    value: 1520,
    label: "1520",
  },
  {
    value: 2020,
    label: "2020",
  },
  {
    value: 2520,
    label: "2520",
  },
  {
    value: 3020,
    label: "3020",
  },
  {
    value: 3520,
    label: "3520",
  },
  {
    value: 4020,
    label: "4020",
  },
  {
    value: 4520,
    label: "4520",
  },
  {
    value: 5000,
    label: "5000",
  },
];

const replica_marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 24,
    label: "24",
  },
  {
    value: 28,
    label: "28",
  },
  {
    value: 32,
    label: "32",
  },
];

export default function Home({ setConfig }) {
  const [version, setVersion] = useState(17);
  const [ram, setRam] = useState(150);
  const [cpu, setCpu] = useState(8);
  const [diskType, setDiskType] = useState(1);
  const [diskSize, setDiskSize] = useState(4);
  const [databaseSize, setDatabaseSize] = useState(75);
  const [workload, setWorkload] = useState(1);
  const [connection, setConnection] = useState(100);
  const [replica, setReplica] = useState(0);
  const [backup, setBackup] = useState(1);
  const [transaction, setTransaction] = useState(0);
  const [experimental, setExperimental] = useState(0);
  const [recycle, setRecycle] = useState(1);


  const handleVerionChange = (e) => {
    setVersion(e.target.value);
  };

  const handleCpuChange = (e) => {
    setCpu(e.target.value);
  };

  const handleDiskTypeChange = (e) => {
    setDiskType(e.target.value);
  };

  const handleDiskSizeChange = (e) => {
    setDiskSize(e.target.value);
  };

  const handleWorkloadChange = (e) => {
    setWorkload(e.target.value);
  };

  const handleConnectionChange = (e) => {
    setConnection(e.target.value);
  };

  const handleReplicaChange = (e) => {
    setReplica(e.target.value);
  };

  const handleBackupChange = (e) => {
    setBackup(e.target.value);
  };

  const handleTransactionChange = (e) => {
    setTransaction(e.target.value);
  };

  const handleExperimentalChange = (e) => {
    setExperimental(e.target.value);
  };

  const handleRecycleChange = (e) => {
    setRecycle(e.target.value);
  };


  useEffect(() => {
    console.log(
      version +
        " " +
        ram +
        " " +
        cpu +
        " " +
        diskType +
        " " +
        diskSize +
        " " +
        databaseSize +
        " " +
        workload +
        " " +
        connection +
        " " +
        replica +
        " " +
        backup +
        " " +
        transaction
    );
  }, [
    version,
    ram,
    cpu,
    diskType,
    diskSize,
    databaseSize,
    workload,
    connection,
    replica,
    backup,
    transaction,
  ]);

  return (
    <Grid container flexDirection={"row"} justifyContent={"center"}>
      <Grid item xs={12} md={4}>
        <Box p={2}>
          <Paper>
            <Box p={5}>
              <Box mb={3}>
                <Typography>Select your version of PostgreSQL:</Typography>
                <FormControl fullWidth size="small">
                  <Select
                    variant={"outlined"}
                    value={version}
                    ram={ram}
                    onChange={handleVerionChange}
                  >
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={3}>
                <NonLinearSlider
                  marks={ram_marks}
                  header={"GB of RAM in your server:"}
                  min={0}
                  max={275}
                  step={25}
                  value={ram}
                  setValue={setRam}
                />
              </Box>

              <Box mb={3}>
                <Typography>Number of CPUs (= cores):</Typography>
                <Slider
                  marks={cpu_marks}
                  min={1}
                  max={72}
                  step={1}
                  valueLabelDisplay="auto"
                  onChange={handleCpuChange}
                  value={cpu}
                />
              </Box>
              <Box mb={3}>
                <Typography>Disk Type:</Typography>
                <FormControl fullWidth size="small">
                  <Select
                    variant={"outlined"}
                    value={diskType}
                    onChange={handleDiskTypeChange}
                  >
                    <MenuItem value={1}>SSD</MenuItem>
                    <MenuItem value={2}>HDD</MenuItem>
                    <MenuItem value={3}>Network (SAN)</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={3}>
                <Typography>Number of disks:</Typography>
                <Slider
                  marks={disk_marks}
                  min={1}
                  max={32}
                  step={1}
                  valueLabelDisplay="auto"
                  value={diskSize}
                  onChange={handleDiskSizeChange}
                />
              </Box>
              <Box mb={3}>
                <NonLinearSlider
                  marks={database_size_marks}
                  header={"How big is your database?"}
                  min={0}
                  max={125}
                  step={25}
                  value={databaseSize}
                  setValue={setDatabaseSize}
                />
              </Box>
              <Box mb={3}>
                <Typography>How would you describe your workload?</Typography>
                <FormControl fullWidth size="small">
                  <Select
                    variant={"outlined"}
                    value={workload}
                    onChange={handleWorkloadChange}
                  >
                    <MenuItem value={1}>
                      Mostly simple short transactions (OLTP)
                    </MenuItem>
                    <MenuItem value={2}>
                      Mostly simple SQL with occasional complicated SQL
                    </MenuItem>
                    <MenuItem value={3}>
                      Mostly complicated real time SQL queries
                    </MenuItem>
                    <MenuItem value={4}>
                      Purely analytical and large aggregations
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={3}>
                <Typography>
                  How many concurrent open connections do you expect?
                </Typography>
                <Slider
                  marks={connection_marks}
                  min={20}
                  max={5000}
                  step={20}
                  valueLabelDisplay="auto"
                  value={connection}
                  onChange={handleConnectionChange}
                />
              </Box>
              <Box mb={3}>
                <Typography>How many replicas do you need?</Typography>
                <Slider
                  marks={replica_marks}
                  min={0}
                  max={32}
                  step={1}
                  valueLabelDisplay="auto"
                  value={replica}
                  onChange={handleReplicaChange}
                />
              </Box>
              <Box mb={3}>
                <Typography>
                  Which backup method are you planning to use?
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    variant={"outlined"}
                    value={backup}
                    onChange={handleBackupChange}
                  >
                    <MenuItem value={1}>pg_dump: Textual dumps</MenuItem>
                    <MenuItem value={2}>pg_basebackup: Binary backups</MenuItem>
                    <MenuItem value={3}>pglogical and alike</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={3} display={version >= 15? "block":"none"}>
                <Typography>
                 Do you want to activate wal recycling?
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={1}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Yes"
                      onChange={handleRecycleChange}
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="No"
                      onChange={handleRecycleChange}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mb={3}>
                <Typography>
                  Can you lose single transactions in case of a crash?
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={0}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Yes"
                      onChange={handleTransactionChange}
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="No"
                      onChange={handleTransactionChange}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <Typography>
                  Are you willing to try out experimental features for better
                  performance?
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={0}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Yes"
                      onChange={handleExperimentalChange}
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="No"
                      onChange={handleExperimentalChange}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box p={2}>
          <Paper>
            <Box p={3}>
              <ConfigGenerator
                ram={ram}
                databaseSize={databaseSize}
                diskType={diskType}
                diskSize={diskSize}
                connection={connection}
                workload={workload}
                backup={backup}
                replica={replica}
                transaction={transaction}
                version={version}
                cpu={cpu}
                experimental={experimental}
                recycle={recycle}
                setConfig={setConfig}
              />
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
import React from "react";
import { Box, Typography, Link } from "@mui/material";

export default function Footer() {
  return (
    <Box
      bgcolor={"footer.main"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={1}
      mt={5}
    >
      <Link
        underline={"none"}
        href="https://www.cybertec-postgresql.com/en/"
        color="white.main"
      >
        © 2000–2024 CYBERTEC PostgreSQL International GmbH
      </Link>
    </Box>
  );
}

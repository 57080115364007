import React, {useState} from "react";
import Home from "./pages/Home";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuBar from "./components/MenuBar";
import Footer from "./components/Footer"

let theme = createTheme({
  palette: {
    primary: {
      main: "#4ec1ec",
      dark: "#41404a",
    },
    secondary: {
      main: "#153558",
    },
    white: {
      main: "#ffffff",
    },
    text: {
      main: "#011037",
      light: "#787878",
    },
    footer: {
      main: "#202630",
    },
  },
});

theme.typography.cybertecheadding = {
  fontSize: "1rem",
  FontFamily: "Montserrat",
  fontWeight: 450,
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};


theme.typography.code = {
  fontSize: "1rem",
  FontFamily: "Roboto Mono",
  fontWeight: 400,
};


export default function App() {
  const [config, setConfig] = useState("");
  return (
    <ThemeProvider theme={theme}>
      <MenuBar config={config} />
      <Home setConfig={setConfig} />
      <Footer/>
    </ThemeProvider>
  );
}
